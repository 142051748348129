import axios from 'axios'
import store from '../store'
import baseUrl from './baseUrl'

const errors = err => {
  console.error(err.response ? err.response.data.stack : err.stack)
  return Promise.reject(err.response ? err.response.data.message:'Houve uma falha na comunição, verifique sua internet e tente novamente')
}

const headers = {token: localStorage.getItem('token')}
const playlists = axios.create({ baseURL: baseUrl.tnAdmin, headers })

export const createPlaylis = () => playlists.post('/playlists', store.state.playlist.newPlaylist).then(({data}) => data).catch(errors)

export const deletePlaylis = (id) => playlists.delete('/playlists/'+id).then(({data}) => data).catch(errors)

export const updatePlaylist = (id) => playlists.put('/playlists/'+id, store.state.playlist.editedPlaylist).then(({data}) => data).catch(errors)

export const getPlaylistById = (id) => playlists.get('/playlists/'+id).then(({data}) => {
  store.state.playlist.editedPlaylist = data
  store.state.playlist.itemsSelected = [...data.items, { text: 1, _id: '1133', time: 0 }]
}).catch(errors)

export const gePlaylists = (options) => playlists.get('/playlists', {params: options}).then(({data}) => {
  store.commit('playlist/setData', data.items)
  store.commit('playlist/setTotalItems', data.total)
  //store.state.playlist.data = data.items
}).catch(errors)

export const getNewsActives = () => playlists.get('/playlists/news').then(({data}) => {
  data.forEach(item => item.itemType = 'Notícia');
  const ids = store.state.playlist.itemsSelected.map(item => item._id)
  const items = data.filter(item => !ids.includes(item._id))
  store.state.playlist.itemsNews = items
}).catch(errors)

export const getAdsActives = () => playlists.get('/playlists/ads').then(({data}) => {
  data.forEach(item => item.itemType = 'Anúncio');
  const ids = store.state.playlist.itemsSelected.map(item => item._id)
  const items = data.filter(item => !ids.includes(item._id))
  store.state.playlist.itemsAds = items
}).catch(errors)
