var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.$store.state.playlist.data,"sort-by":"createdAt","sort-desc":"","loading":_vm.loading,"options":_vm.options,"loading-text":"Carregando...","server-items-length":_vm.$store.state.playlist.totalItems,"footer-props":{'items-per-page-options': [10,15,20], firstIcon: 'mdi-arrow-collapse-left', lastIcon: 'mdi-arrow-collapse-right','items-per-page-text':'Playlists por página'}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Playlists")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('tooltip'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',{attrs:{"loading":_vm.loadingDelete}},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Excluir Playlist "+_vm._s(_vm.itemToRemove.name)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"red darken-1","loading":_vm.loadingDelete,"text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Deletar")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.status ? 'green' : 'red',"size":"20","title":item.status ? 'online' : 'offline'}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.news",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.items.filter(function (data) { return data.itemType == "Notícia"; }).length)+" ")]}},{key:"item.ads",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.items.filter(function (data) { return data.itemType == "Anúncio"; }).length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString())+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" Nenhum totem cadastrado ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }