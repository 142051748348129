<template>
  <v-data-table
    :headers="headers"
    :items="$store.state.playlist.data"
    sort-by="createdAt"
    sort-desc
    class="elevation-1"
    :loading="loading"
    :options.sync="options"
    loading-text="Carregando..."
    :server-items-length="$store.state.playlist.totalItems"
    :footer-props="{'items-per-page-options': [10,15,20], firstIcon: 'mdi-arrow-collapse-left', lastIcon: 'mdi-arrow-collapse-right','items-per-page-text':'Playlists por página'}"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Playlists</v-toolbar-title>

        <v-divider class="mx-4" inset vertical></v-divider>

        <v-spacer></v-spacer>

        <tooltip></tooltip>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card :loading="loadingDelete">
            <v-card-title class="headline"
              >Excluir Playlist {{ itemToRemove.name }}?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete = false"
                >Cancelar</v-btn
              >
              <v-btn
                color="red darken-1"
                :loading="loadingDelete"
                text
                @click="deleteItemConfirm"
                >Deletar</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.status="{ item }">
      <v-avatar
        :color="item.status ? 'green' : 'red'"
        size="20"
        :title="item.status ? 'online' : 'offline'"
      >
      </v-avatar>
    </template>

    <template v-slot:item.name="{ item }">
      <span class="link" @click="editItem(item)">{{ item.name }}</span>
    </template>

    <template v-slot:item.news="{ item }">
      {{ item.items.filter((data) => data.itemType == "Notícia").length }}
    </template>

    <template v-slot:item.ads="{ item }">
      {{ item.items.filter((data) => data.itemType == "Anúncio").length }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>

    <template v-slot:item.createdAt="{ item }">
      {{ new Date(item.createdAt).toLocaleString() }}
    </template>

    <template v-slot:no-data=""> Nenhum totem cadastrado </template>
  </v-data-table>
</template>

<script>
import Tooltip from "./tooltip";
import lodash from "lodash";
import _ from "lodash";
import { deletePlaylis, gePlaylists } from "../../services/playlists";
export default {
  components: {
    Tooltip,
  },
  data: () => ({
    timeoutToSearch: 0,
    options: {sortBy: ['createdAt'], sortDesc: [true], filter: {}},
    loading: false,
    loadingDelete: false,
    dialogDelete: false,
    headers: [
      { text: "Nome", align: "start", value: "name" },
      { text: "Tipo", value: "type", align: "center" },
      { text: "Usuário", value: "user.name", align: "center" },
      { text: "Notícias", value: "news", align: "center" },
      { text: "Anúncios", value: "ads", align: "center" },
      { text: "Data", value: "createdAt", align: "center" },
      { text: "Actions", value: "actions", sortable: false, align: "center" },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    itemToRemove: {},
  }),

  methods: {
    lodash() {
      return lodash;
    },

    editItem(item) {
      this.$router.push("/playlists/edit/" + item._id);
    },

    deleteItem(item) {
      this.itemToRemove = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loadingDelete = true;
      deletePlaylis(this.itemToRemove._id)
        .then(() => {
          this.$store.commit("success", "Playlist deletada com sucesso.");
          this.loadingDelete = false;
          this.dialogDelete = false;
          const index = _.findIndex(this.$store.state.playlist.data, {
            _id: this.itemToRemove._id,
          });
          this.$store.state.playlist.data.splice(index, 1);
        })
        .catch((err) => {
          this.$store.commit("failed", "ERRO: " + err);
          this.loadingDelete = false;
          this.dialogDelete = false;
        });
    },
  },
  async mounted() {

    const obj = Object.assign({}, this.$store.state.playlist.resetPlaylist);
    Object.keys(obj).forEach((key) => {
      this.$store.state.playlist[key] = Array.isArray(obj[key])
        ? Array.from(obj[key])
        : Object.assign({}, obj[key]);
    });
  },
  
  watch:{
    options: {
        handler () {
          this.loading = true;
          clearTimeout(this.timeoutToSearch)
          this.timeoutToSearch = setTimeout(()=>{
            gePlaylists(this.options).catch((err) => this.$state.commit("failed", "ERRO: " + err))
            .finally(()=>this.loading = false);
          }, 300)
        },
        deep: true,
      },
  }
};
</script>
<style scoped>
.link {
  cursor: pointer;
}
</style>